import apiClient from 'services/axios'

export function getUserData() {
  return apiClient
    .get(`/user/userlist`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function getUserToken(data) {
  return apiClient
    .post(`/user/token`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function getUserLog(data) {
  return apiClient
    .post(`/user/log`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function getUserInvoice(data) {
  return apiClient
    .post(`/user/invoice`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

// {id:clientID ,monthUpdate: set new unixtime}
export function putClientExpired(data) {
  // console.log(data)
  return apiClient
    .put(`/user/expired/client`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

// {id:userid ,monthUpdate: set new unixtime ,acctype: current Account type}
export function putEngineExpired(data) {
  // recived data = 0
  console.log('a data of EngineExpired service: ', data)
  return apiClient
    .put(`/user/expired/engine`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

// {id:userid ,monthUpdate: set new unixtime ,acctype: current Account type}
export function putUserExpired(data) {
  console.log('putUserExpired Data: ', data)
  return apiClient
    .put(`user/expired/user`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

// {id:userid ,autobetType:0..2 , role: 0..6}
export function putUserType(data) {
  return apiClient
    .put(`/user/type`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function putUserPaid(data) {
  return apiClient
    .put(`/user/paid`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
