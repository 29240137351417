import { call, put } from 'redux-saga/effects'
import { getClientData } from 'services/client' // eslint-disable-line
import actionTypes from './actiontypes'

export function* fetchClientAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(getClientData)
    const { datas = [] } = payload
    console.log('datas are:', datas)
    yield put({ type: actionTypes.SET_STATE, payload: { client: datas } })
  } catch (e) {}
}
