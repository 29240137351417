import apiClient from 'services/axios'

export function fetchServer() {
  return apiClient
    .get(`/server/list`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setClientConfig(data) {
  return apiClient
    .post(`/server/confclient`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setServerConfig(data) {
  return apiClient
    .post(`/server/confserver`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
