import actionTypes from './actiontypes'

const initialState = {
  banklist: [],
  isLoading: false,
}

export default function banklistReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      // no logic
      return { ...state, ...action.payload }

    /*
    case actionTypes.SET_STATE_BANKLIST: {
      // logic หา id user
      console.log('', action.payload)
      const { key, amount } = action.payload
      const index = state.banklist.findIndex(e => e._id === key) // eslint-disable-line
      const tmp = [...state.banklist]
      if (index > -1) {
        tmp[index].amount = amount
      }
      return { ...state, banklist: tmp }
    }
*/
    default:
      return state
  }
}
