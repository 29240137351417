import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import user789 from './user789/sagas'
import banklist from './banklist/sagas'
import invoices from './invoices/sagas'
import client from './client/sagas'
import servers from './servers/sagas'
import failedReport from './failedReport/sagas'
import stakeSummary from './stakeSummary/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    user789(),
    banklist(),
    invoices(),
    client(),
    servers(),
    failedReport(),
    stakeSummary()
  ])
}
