const actiontypes = {
  SET_STATE: 'user789/SET_STATE',
  SET_STATE_USER: 'user789/SET_STATE_USER',
  SET_LOADING: 'user789/SET_LOADING',

  GET_USERS_REQ: 'user789/GET_USERS_REQ',
  GET_USER_TOKEN_REQ: 'user789/GET_USER_TOKEN_REQ',
  GET_USER_LOG_REQ: 'user789/GET_USER_LOG_REQ',
  GET_USER_INVOICE_REQ: 'user789/GET_USER_INVOICE_REQ',

  SET_USERS_REQ: 'user789/SET_USERS_REQ',
  SET_USER_TYPE_REQ: 'user789/SET_USER_TYPE_REQ',
  SET_USER_PAID_REQ: 'user789/SET_USER_PAID_REQ',
  SET_CLIENT_EXPIRED_REQ: 'user789/SET_CLIENT_EXPIRED',
  SET_USER_EXPIRED_REQ: 'user789/SET_USER_EXPIRED',
  SET_ENGINE_EXPIRED_REQ: 'user789/SET_ENGINE_EXPIRED',
}

export default actiontypes
