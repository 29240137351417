import { call, put } from 'redux-saga/effects'
import { postStakeSummaryData, getUsersData } from 'services/stakeSummary' // eslint-disable-line
import actionTypes from './actiontypes'

export function* fetchStakeSummaryAction(person) {
  // param ===> { type: actionTypes.POST_STAKE_SUMMARY_REQ, payload: {_id:person} }
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(postStakeSummaryData, person.payload)
    console.log('payload stakeSummary: ', payload)
    const { datas = [] } = payload
    yield put({ type: actionTypes.SET_STATE, payload: { stakeSummary: datas, isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
export function* fetchUsersAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(getUsersData)
    console.log('payload Users stakeSummary: ', payload)
    const { users = [] } = payload
    yield put({ type: actionTypes.SET_STATE, payload: { users, isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
