import apiClient from 'services/axios'

export function getBankListData() {
  return apiClient
    .get(`/banklist`)
    .then(response => {
      console.log('service data: ', response.data)
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
