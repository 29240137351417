import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: 'https://api.soccer789.com/admin/',
  // baseURL: 'http://192.168.1.232/admin/',
  // timeout: 1000,
  // headers: { 'x-auth-token': 'test_admin' },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers = { 'admin-auth-token': accessToken }
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data = { msg: '' }, status } = response
  const { msg = '' } = data
  if (status === 400) {
    if (msg) {
      notification.warning({
        message: msg,
      })
    }
  } else if (status === 401) {
    const { statusText = '' } = response
    notification.error({ message: statusText })
  }
})

export default apiClient
