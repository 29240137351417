import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import {
  fetchUserAction,
  fetchUserInvoiceAction,
  fetchUserLogAction,
  fetchUserTokenAction,
  putClientAction,
  putEngineAction,
  putUserAction,
  putUserPaidAction,
  putUserTypeAction,
} from './actions'

function* watchGetUserAction() {
  yield takeLatest(actionTypes.GET_USERS_REQ, fetchUserAction)
}

function* watchGetUserTokenAction() {
  yield takeLatest(actionTypes.GET_USER_TOKEN_REQ, fetchUserTokenAction)
}

function* watchGetUserLogAction() {
  yield takeLatest(actionTypes.GET_USER_LOG_REQ, fetchUserLogAction)
}

function* watchGetUserInvoiceAction() {
  yield takeLatest(actionTypes.GET_USER_INVOICE_REQ, fetchUserInvoiceAction)
}

function* watchPutClientExpiredAction() {
  yield takeLatest(actionTypes.SET_CLIENT_EXPIRED_REQ, putClientAction)
}

function* watchPutEngineExpiredAction() {
  yield takeLatest(actionTypes.SET_ENGINE_EXPIRED_REQ, putEngineAction)
}

function* watchPutUserExpiredAction() {
  yield takeLatest(actionTypes.SET_USER_EXPIRED_REQ, putUserAction)
}

function* watchPutUserTypeAction() {
  yield takeLatest(actionTypes.SET_USER_TYPE_REQ, putUserTypeAction)
}

function* watchPutUserPaidAction() {
  yield takeLatest(actionTypes.SET_USER_PAID_REQ, putUserPaidAction)
}

export default function* rootSaga() {
  yield all([
    watchGetUserAction(),
    watchGetUserTokenAction(),
    watchGetUserLogAction(),
    watchGetUserInvoiceAction(),
    watchPutClientExpiredAction(),
    watchPutEngineExpiredAction(),
    watchPutUserExpiredAction(),
    watchPutUserTypeAction(),
    watchPutUserPaidAction(),
  ])
}
