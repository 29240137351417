import apiClient from 'services/axios'

export function getFailedReportData() {
  return apiClient
    .get(`/failed_report`) //eslint-disable-line
    .then(response => {
      console.log('service data: ', response.data)
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
