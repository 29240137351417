export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Reports',
    },
    {
      title: 'Failed Report',
      key: 'Failed_Report',
      icon: 'fe fe-clipboard',
      url: '/dashboard/failed_report',
    },
    {
      title: 'Stake Summary',
      key: 'Stake_Summary',
      icon: 'fe fe-clipboard',
      url: '/dashboard/stake_summary',
    },
    {
      category: true,
      title: 'Datas',
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fe fe-users',
      url: '/dashboard/user',
    },
    {
      title: 'Invoices',
      key: 'Invoices',
      icon: 'fe fe-file',
      url: '/dashboard/invoices',
    },
    {
      title: 'PaymentList',
      key: 'Paymentlist',
      icon: 'fe fe-file-text',
      url: '/dashboard/paymentlist',
    },
    {
      title: 'Servers',
      key: 'serveredit',
      icon: 'fe fe-server',
      url: '/server/list',
    },
    {
      title: 'Client',
      key: 'Client',
      icon: 'fe fe-monitor',
      url: '/dashboard/client',
    },
  ]
}
