import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchBankListAction } from './actions'

function* watchGetBankListAction() {
  yield takeLatest(actionTypes.GET_BANKLIST_REQ, fetchBankListAction)
}


export default function* rootSaga() {
  yield all([
    watchGetBankListAction(),
  ])
}
