const actiontypes = {

  SET_STATE: 'banklist/SET_STATE',
  SET_STATE_BANKLIST: 'banklist/SET_STATE_USER',
  SET_LOADING: 'banklist/SET_LOADING',

  GET_BANKLIST_REQ: 'banklist/GET_BANKLIST_REQ',

}

export default actiontypes
