import localeAntd from 'antd/es/locale/th_TH'

const messages = {
  'topBar.profileMenu.hello': 'สวัสดี',
  'topBar.profileMenu.role': 'สิทธิ์',
  'topBar.profileMenu.email': 'อีเมล์',
  'topBar.profileMenu.phone': 'เบอร์',
  'topBar.profileMenu.expired': 'วันหมดอายุบัญชี',
  'topBar.profileMenu.autobetExpired': 'วันหมดอายุ Engine',
  'topBar.profileMenu.clientExpired': 'วันหมดอายุบอท',
  'topBar.profileMenu.editProfile': 'แก้ไขข้อมูล',
  'topBar.profileMenu.logout': 'ออกจากระบบ',
  'topBar.profileMenu.login': 'เข้าใช้งาน',

  'porta.helmet': 'พอร์ตลงทุน A',
  'portb.helmet': 'พอร์ตลงทุน B',
  'portc.helmet': 'พอร์ตลงทุน C',
  'portd.helmet': 'พอร์ตลงทุน D',

  'automated.helmet': 'ลงทุนอัตโนมัติ',
  'automated.dashboard.helmet': 'ข้อมูลสรุป',
  'automated.dashboard.guarantee.helmet': 'สิทธิ์การันตีกำไร',
  'automated.dashboard.balance.helmet': 'ยอดเงินปัจจุบัน',
  'automated.dashboard.balance.remain.helmet': 'คงเหลือ',
  'automated.dashboard.balance.stake.helmet': 'ลงทุน',
  'automated.dashboard.stat24.helmet': 'สถิติ 24 ชั่วโมง',
  'automated.dashboard.statoverall.helmet': 'สถิติทั้งหมด',
  'automated.dashboard.statmonthly.helmet': 'สรุปรายเดือน',
  'automated.dashboard.currentbet.helmet': 'รายการลงทุนปัจจุบัน',
  'automated.strategy.helmet': 'แผนการลงทุน',
  'automated.stakedlist.helmet': 'รายการที่ลงทุน',
  'automated.stakedlist.delbtn.helmet': 'ลบรายการเก่า',
  'automated.stakedlist.deldesc.helmet': 'ข้อมูลเก่าเกินกว่า 90 วันจะถูกลบออก',

  'condition.helmet': 'ตรวจจับเงื่อนไข',
  'condition.livefeed.helmet': 'รายการบอลที่เข้าเงื่อนไข',
  'condition.statistic.helmet': 'สถิติเงื่อนไข',
  'condition.management.helmet': 'จัดการเงื่อนไข',
  'condition.dashboard.helmet': 'ข้อมูลสรุป',

  'setting.helmet': 'ตั้งค่า',
  'setting.client.helmet': 'บอทสำหรับลงทุนอัตโนมัติ (Client)',
  'setting.notification.helmet': 'การแจ้งเตือน',

  'hall.helmet': 'ทำเนียบ',
  'hall.dashboard.helmet': 'ข้อมูลสรุป',
  'hall.dashboard.system.helmet': 'สถิติเงื่อนไขของระบบ',
  'hall.dashboard.hallofframe.helmet': 'ทำเนียบเงื่อนไขทั้งหมด',
  'hall.dashboard.top7day.helmet': 'Top 7 วัน',
  'hall.dashboard.top31day.helmet': 'Top 1 เดือน',
  'hall.dashboard.top3month.helmet': 'Top 3 เดือน',
  'hall.dashboard.top6month.helmet': 'Top 6 เดือน',

  'signup.form.header':'สมัครสมาชิก',
  'signup.form.summit':'ลงทะเบียน',
  'signup.txt.asksignup':'ยังไม่เป็นสมาชิก?',
  'signup.txt.asksignin':'เป็นสมาชิกแล้ว?',

  'signin.txt.forgot.header':'ลืมรหัสผ่าน',
  'signin.txt.forgot.reset':'รีเซ็ตรหัสผ่าน',
  'signin.txt.forgot.resetbtn':'ส่งอีเมล์สำหรับรีเซ็ต',
  'signin.txt.forgot.desc':'กรุณาระบุอีเมล์ที่ใช้ลงทะเบียนกับเรา',
  'signin.txt.forgot.newpassword':'ตั้งค่ารหัสผ่านใหม่',
  'signin.txt.forgot.newpassdesc':'กรุณาตั้งค่ารหัสผ่านใหม่',
  'signin.txt.forgot.resetsavebtn':'บันทึก',

  'help.contactus' : 'ติดต่อเรา',

  'text.follow': 'ติดตาม',
  'text.unFollow': 'เลิกติดตาม',
  'text.slips': 'ลงทุน(ครั้ง)',
  'text.spent': 'เงินหมุนเวียน',
  'text.profit': 'กำไร',
  'text.delete': 'ลบ',
  'text.deleteConditionConfirm': 'เงื่อนไขนี้และข้อมูลสถิติเงื่อนไขนี้ทั้งหมดจะถูกลบออกจากระบบ',
  'text.cancelOrderConfirm': 'ยืนยันยกเลิกการสั่งซื้อ',
  'text.current': 'รายการปัจจุบัน',
  'text.create': 'สร้าง',
  'text.limitcondition': 'จำกัดการสร้าง 20 เงื่อนไข ต่อ 1 บัญชีผู้ใช้งาน',
  'text.exceedlimit': 'ท่านจะสามารถสร้างเงื่อนไขเพิ่มได้ เมื่อจำนวนเงื่อนไขน้อยกว่า 20',
  'text.mystrategy': 'เงื่อนไขส่วนตัว',
  'text.followstrategy': 'เงื่อนไขที่ติดตาม',
  'text.systemstrategy': 'เงื่อนไขระบบ',
  'text.limitstake': 'ขั้นต่ำในการวางบิล(STAKE) = 30 บาท(THB), 1$(USD)',

  'text.profitGuarantee': 'ได้รับสิทธิ์การันตีกำไร ใช้ Full Engine ฟรีอีก 31 วัน',

  'text.okay': 'ตกลง',
  'text.ordercancel': 'ยกเลิกการสั่งซื้อ',
  'text.order': 'สั่งซื้อ',
  'text.order.status': 'สถานะ',
  'text.order.invoice': 'หมายเลขการสั่งซื้อ',
  'text.order.channel': 'ช่องทางชำระเงิน',
  'text.order.date': 'วันที่',
  'text.order.time': 'เวลา',
  'text.order.costaddition' : 'ส่วนเกินค่าบริการจะถูกเพิ่มเวลาการใช้งานให้อีก 3 ชม.',
  'text.ordernow': 'ยันยืนการสั่งซื้อ',
  'text.orderdone': 'ทำรายการเรียบร้อย',
  'text.orderlist': 'รายการสั่งซื้อ',
  'text.orderpaymentselect': 'เลือกช่องทางชำระเงิน',
  'text.orderpaymentnotify': 'แจ้งชำระเงิน',
  'text.grandtotal': 'รวมทั้งหมด',
  'text.grandtotalpay': 'ยอดที่ต้องชำระ',
  'text.baht': 'บาท',
  'text.day': 'วัน',
  
  'text.status.done': 'ทำรายการเสร็จสมบูรณ์ ขอบคุณที่ใช้บริการกับเรา',
  'text.status.done.userexpired': 'วันหมดอายุบัญชี จาก',
  'text.status.done.engineexpired': 'วันหมดอายุ Engine จาก',
  'text.status.done.clientexpired': 'วันหมดอายุบอท(Client) จาก',
  'text.from': 'จาก',
  'text.to': 'เป็น',

  'pricing.helmet': 'รูปแบบบริการ',
  'pricing.chart': 'กราฟสถิติฟุตบอลสด',
  'pricing.chart.desc': 'กราฟสถิติฟุตบอลสด',
  'pricing.notification': 'การแจ้งเตือนเงื่อนไข',
  'pricing.notification.desc': 'กราฟสถิติฟุตบอลสด',
  'pricing.strategy': 'สร้างเงื่อนไข',
  'pricing.strategy.desc': 'ตรวจจับเงื่อนไขตามรูปแบบเกมส์ จำกัด 20 เงื่อนไขต่อผู้ใช้งาน',
  'pricing.selfengine': 'ใช้เงื่อนไขส่วนตัวเพื่อลงทุนอัตโนมัติ',
  'pricing.selfengine.desc': 'ใช้เงื่อนไขส่วนตัวเพื่อลงทุนอัตโนมัติ',
  'pricing.followcondition': 'ติดตาม/ใช้เงื่อนไขในทำเนียบเพื่อลงทุนอัตโนมัติ',
  'pricing.followcondition.desc': 'ติดตามเงื่อนไขในทำเนียบ',
  'pricing.systemcondition': 'ติดตาม/ใช้เงื่อนไขระบบเพื่อลงทุนอัตโนมัติ',
  'pricing.systemcondition.desc': 'ติดตามเงื่อนไขระบบเพื่อใช้ลงทุนอัตโนมัติ',
  'pricing.guarantee': 'การันตีกำไร 4 หมื่น/เดือน*',
  'pricing.guarantee.desc': 'หากกำไรไม่ถึง 40,000 บาท ใช้ Full Engine ฟรีในเดือนถัดไป',
  'pricing.robot': 'บอทสำหรับลงทุนอัตโนมัติ*',
  'pricing.robot.desc': 'บอทสำหรับลงทุนอัตโนมัติ*',
  'pricing.robot.explain': 'เพื่อลดปัญหาที่เคยเกิดขึ้นจากที่ผู้ใช้งานต้องดูแลบอทเอง ทางทีมงานจะนำค่าใช้จ่ายส่วนนี้ ไปทำการเช่าระบบ Cloud Computer เพื่อใช้สำหรับเปิดบอท และคอยตรวจสอบปัญหาต่างๆ ที่เกิดขึ้น',
  'pricing.notincludebot': 'ไม่รวมบอทสำหรับลงทุนอัตโนมัติ',

  'orderstep.order': 'เลือกรูปแบบบริการ',
  'orderstep.payment': 'ชำระเงินตามจำนวน',
  'orderstep.paymentnotify': 'แจ้งชำระเงิน',
  'orderstep.paymentnotify.notfound': 'ไม่พบรายการชำระดังกล่าว',
  'orderstep.paymentnotify.desc1': 'กรุณาตรวจสอบยอดเงินโอนและวันเวลาให้ตรงตามสลิปการโอนอีกครั้ง',
  'orderstep.paymentnotify.desc2': 'หรือติดต่อเจ้าหน้าที่',
  'orderstep.finished': 'เสร็จสิ้น',
  'orderstep.payment.banktrasfer': 'โอนผ่านธนาคาร',
  'orderstep.payment.banktrasfer.notfify': 'แจ้งชำระเงิน',
  'orderstep.payment.paypal': 'Paypal',
  'orderstep.done.client' : 'ระบบจะทำการเช่า Cloud และติดตั้ง Client(บอท) โดยใช้เวลา 1-5 นาที',

  'table.orderplan': 'รูปแบบ',
  'table.orderquantity': 'จำนวน',
  'table.orderunit': 'ราคา',
  'table.ordertotal': 'รวม',
  'table.strategy': 'เงื่อนไข',
  'table.win': 'ชนะ',
  'table.draw': 'เสมอ',
  'table.lost': 'แพ้',
  'table.profit': 'กำไร',
  'table.match': 'คู่',
  'table.rate': 'เรท',
  'table.lastmonthprofit': 'สถิติเดือนล่าสุด',
  'table.result': 'ผลลัพธ์',
  'table.stake': 'ลงทุน',
  'table.return': 'ได้รับ',
  'table.time': 'เวลา',
}

export default {
  locale: 'th-TH',
  localeAntd,
  messages,
}
