import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchFailedReportAction } from './actions'

function* watchGetFailedReportAction() {
  yield takeLatest(actionTypes.GET_FAILED_REPORT_REQ, fetchFailedReportAction)
}

export default function* rootSaga() {
  yield all([watchGetFailedReportAction()])
}
