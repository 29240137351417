import { notification } from 'antd'
import { call, delay, put } from 'redux-saga/effects'
import { fetchServer, setClientConfig, setServerConfig } from 'services/server'
import actionTypes from './actiontypes'

export function* fetchServerAction() {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchServer)
    const { servers = [], users = [] } = payload
    yield put({ type: actionTypes.SET_STATE, payload: { servers, users, isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setClientConfigAction(param) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(setClientConfig, param.payload)
    const { data = { result: 0 } } = payload
    const nofType = data.result ? 'success' : 'error'
    yield delay(1000)
    notification[nofType]({ message: nofType })
    if (data.result > 0) {
      yield put({ type: actionTypes.SET_STATE, payload: { result: data.result } })
      yield delay(2000)
      yield put({ type: actionTypes.GET_SERVER_REQ })
    } else
      yield put({ type: actionTypes.SET_STATE, payload: { result: data.result, isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setServerConfigAction(param) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(setServerConfig, param.payload)
    const { data = { result: 0 } } = payload
    const nofType = data.result ? 'success' : 'error'
    yield delay(1000)
    notification[nofType]({ message: nofType })
    if (data.result > 0) {
      yield put({ type: actionTypes.SET_STATE, payload: { result: data.result } })
      yield delay(2000)
      yield put({ type: actionTypes.GET_SERVER_REQ })
    } else
      yield put({ type: actionTypes.SET_STATE, payload: { result: data.result, isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
