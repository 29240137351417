import { call, put } from 'redux-saga/effects'
import { getBankListData } from 'services/banklist' // eslint-disable-line
import actionTypes from './actiontypes'

export function* fetchBankListAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(getBankListData)
    const { datas = [] } = payload
    yield put({ type: actionTypes.SET_STATE, payload: { banklist: datas } })
  } catch (e) {}
}
