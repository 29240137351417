import actionTypes from './actiontypes'

const initialState = {
  users: [],
  logs: [],
  invoices: [],
  isLoading: false,
  isSaving: false,
  isLogLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      // no logic
      // { ...state, ...action.payload }
      // state ค่าเดิมของ state ที่เป็นกฏของ Redux ว่าต้องไม่เปลี่ยนเเปลงค่า state เดิม
      // action.payload คือค่า state ใหม่
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_USER: {
      // logic หา id user
      console.log(action.payload)
      const { key, username } = action.payload
      const index = state.users.findIndex(e => e._id === key) // eslint-disable-line
      const tmp = [...state.users]
      if (index > -1) {
        tmp[index].username = username
      }
      return { ...state, users: tmp }
    }

    default:
      return state
  }
}
