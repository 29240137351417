import actionTypes from './actiontypes'

const initialState = {
  invoices: [],
  isLoading: false,
}

export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      // no logic
      return { ...state, ...action.payload }

    default:
      return state
  }
}
