import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchStakeSummaryAction, fetchUsersAction } from './actions'

function* watchPostStakeSummaryAction() {
  yield takeLatest(actionTypes.POST_STAKE_SUMMARY_REQ, fetchStakeSummaryAction)
}
function* watchGetUsersAction() {
  yield takeLatest(actionTypes.GET_USERS_REQ, fetchUsersAction)
}

export default function* rootSaga() {
  yield all([watchPostStakeSummaryAction(), watchGetUsersAction()])
}
