import actionTypes from './actiontypes'

const initialState = {
  users: [],
  stakeSummary: {},
  isLoading: false,
}

export default function stakeSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      // no logic
      return { ...state, ...action.payload }

    default:
      return state
  }
}
