import { call, put } from 'redux-saga/effects'
import {
  getUserData,
  putClientExpired,
  putEngineExpired,
  putUserExpired,
  putUserType,
  putUserPaid,
  getUserToken,
  getUserLog,
  getUserInvoice,
} from 'services/user' // eslint-disable-line
import actionTypes from './actiontypes'

export function* fetchUserAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(getUserData)
    const { users = [] } = payload
    yield put({
      type: actionTypes.SET_STATE,
      payload: { users, isLoading: false },
    })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* fetchUserTokenAction(arg) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(getUserToken, arg.payload)
    const { accessToken = '' } = payload
    if (accessToken)
      window.open(
        `https://www.soccer789.com/#/auth/login:${accessToken}`,
        '_blank',
        'noopener,noreferrer',
      )
  } catch (e) {}
}

export function* fetchUserLogAction(arg) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLogLoading: true } })
    const payload = yield call(getUserLog, arg.payload)
    const { logs = [] } = payload
    yield put({
      type: actionTypes.SET_STATE,
      payload: { logs, isLogLoading: false },
    })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLogLoading: false } })
  }
}

export function* fetchUserInvoiceAction(arg) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(getUserInvoice, arg.payload)
    const { invoices = [] } = payload
    yield put({ type: actionTypes.SET_STATE, payload: { invoices } })
  } catch (e) {}
}

// a pattern that why we use -> { data = { users: [] } } = payload
// {
//   code:0,
//   msg:'',
//   data: {
//     users:
//   }
// }

export function* putClientAction(arg) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: true } })
    const payload = yield call(putClientExpired, arg.payload)

    const { data = { users: [] } } = payload
    const { users = [] } = data
    yield put({ type: actionTypes.SET_STATE, payload: { users, isSaving: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } })
  }
}

export function* putEngineAction(arg) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: true } })
    const payload = yield call(putEngineExpired, arg.payload)

    const { data = { users: [] } } = payload
    const { users = [] } = data
    yield put({ type: actionTypes.SET_STATE, payload: { users, isSaving: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } })
  }
}

export function* putUserAction(arg) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: true } })
    const payload = yield call(putUserExpired, arg.payload)

    const { data = { users: [] } } = payload
    const { users = [] } = data
    yield put({ type: actionTypes.SET_STATE, payload: { users, isSaving: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } })
  }
}

export function* putUserTypeAction(arg) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: true } })
    const payload = yield call(putUserType, arg.payload)

    const { data = { users: [] } } = payload
    const { users = [] } = data
    yield put({ type: actionTypes.SET_STATE, payload: { users, isSaving: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } })
  }
}

export function* putUserPaidAction(arg) {
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: true } })
    const payload = yield call(putUserPaid, arg.payload)

    const { data = { users: [] } } = payload
    const { users = [] } = data
    yield put({ type: actionTypes.SET_STATE, payload: { users, isSaving: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } })
  }
}
