import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchInvoiceAction } from './actions'

function* watchGetInvoiceAction() {
  yield takeLatest(actionTypes.GET_INVOICES_REQ, fetchInvoiceAction)
}

export default function* rootSaga() {
  yield all([watchGetInvoiceAction()])
}
