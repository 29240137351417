import localeAntd from 'antd/es/locale/en_US'

// const messages = {
//   'topBar.issuesHistory': 'Issues History',
//   'topBar.projectManagement': 'Project Management',
//   'topBar.typeToSearch': 'Search...',
//   'topBar.findPages': 'Find pages...',
//   'topBar.actions': 'Actions',
//   'topBar.status': 'Status',
//   'topBar.profileMenu.hello': 'Hello',
//   'topBar.profileMenu.billingPlan': 'Billing Plan',
//   'topBar.profileMenu.role': 'Role',
//   'topBar.profileMenu.email': 'Email',
//   'topBar.profileMenu.phone': 'Phone',
//   'topBar.profileMenu.editProfile': 'Edit Profile',
//   'topBar.profileMenu.logout': 'Logout',
// }

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.expired': 'User Expired',
  'topBar.profileMenu.autobetExpired': 'Engine Expired',
  'topBar.profileMenu.clientExpired': 'Client Expired',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.profileMenu.login': 'Login',

  'porta.helmet': 'Portfolio A',
  'portb.helmet': 'Portfolio B',
  'portc.helmet': 'Portfolio C',
  'portd.helmet': 'Portfolio D',

  'automated.helmet': 'Automated betting',
  'automated.dashboard.helmet': 'Dashboard',
  'automated.dashboard.guarantee.helmet': 'Profit Guarantee',
  'automated.dashboard.balance.helmet': 'Balance',
  'automated.dashboard.balance.remain.helmet': 'Remain',
  'automated.dashboard.balance.stake.helmet': 'Stake',
  'automated.dashboard.stat24.helmet': '24HR STATISTICS',
  'automated.dashboard.statoverall.helmet': 'OVERALL STATISTICS',
  'automated.dashboard.statmonthly.helmet': 'MONTHLY SUMMARY',
  'automated.dashboard.currentbet.helmet': 'CURRENT BETS',
  'automated.strategy.helmet': 'Strategies',
  'automated.stakedlist.helmet': 'Staked List',
  'automated.stakedlist.delbtn.helmet': 'Delete old data',
  'automated.stakedlist.deldesc.helmet': 'Delete data more than 90 days',

  'condition.helmet': 'Condition',
  'condition.livefeed.helmet': 'Live Feed',
  'condition.statistic.helmet': 'Statistic',
  'condition.management.helmet': 'Management',
  'condition.dashboard.helmet': 'Dashboard',

  'setting.helmet': 'Setting',
  'setting.client.helmet': 'Client (Bot)',
  'setting.notification.helmet': 'Notification',

  'hall.helmet': 'Hall',
  'hall.dashboard.helmet': 'Dashboard',
  'hall.dashboard.system.helmet': 'System Strategy',
  'hall.dashboard.hallofframe.helmet': 'Hall of frame',
  'hall.dashboard.top7day.helmet': 'Top 7 day',
  'hall.dashboard.top31day.helmet': 'Top 1 month',
  'hall.dashboard.top3month.helmet': 'Top 3 month',
  'hall.dashboard.top6month.helmet': 'Top 6 month',

  'signup.form.header':'Create your account',
  'signup.form.summit':'Sign up',
  'signup.txt.asksignup':'Don\'t have an account?',
  'signup.txt.asksignin':'Already have an account?',

  'signin.txt.forgot.header':'Forgot Password',
  'signin.txt.forgot.reset':'Reset Password',
  'signin.txt.forgot.resetbtn':'Reset my password',
  'signin.txt.forgot.desc':'Please input your email',
  'signin.txt.forgot.newpassword':'Set new password',
  'signin.txt.forgot.newpassdesc':'Please setup your new password',
  'signin.txt.forgot.resetsavebtn':'save',

  'help.contactus' : 'Contact Us',

  'text.follow': 'Follow',
  'text.unFollow': 'Unfollow',
  'text.slips': 'Slips',
  'text.spent': 'Spent',
  'text.profit': 'Profit',
  'text.delete': 'Delete',
  'text.deleteConditionConfirm': 'Strategy data will be removed forever',
  'text.cancelOrderConfirm': 'Cancel order',
  'text.current': 'Current',
  'text.create': 'Create',
  'text.limitcondition': 'Maximum strategy per user is 20',
  'text.exceedlimit': 'Exceed condition limit',
  'text.mystrategy': 'My Strategy',
  'text.followstrategy': 'Follow Strategy',
  'text.systemstrategy': 'System Strategy',
  'text.limitstake': 'ขั้นต่ำในการวางบิล(STAKE) = 30 บาท(THB), 1$(USD)',

  'text.profitGuarantee': 'ได้รับสิทธิ์การันตีกำไร ใช้ Full Engine ฟรีอีก 31 วัน',

  'text.okay': 'OK',
  'text.ordercancel': 'Cancel Order',
  'text.order': 'Order',
  'text.order.status': 'Status',
  'text.order.invoice': 'Invoice',
  'text.order.channel': 'Channel',
  'text.order.date': 'Date',
  'text.order.time': 'Time',
  'text.order.costaddition' : 'Addition cost will be extended time to 3 hrs.',
  'text.ordernow': 'Order Now',
  'text.orderdone': 'Success',
  'text.orderlist': 'Order Items',
  'text.orderpaymentselect': 'Payment Channel',
  'text.orderpaymentnotify': 'Notify Payment',
  'text.grandtotal': 'Grand Total',
  'text.grandtotalpay': 'Total Cost',
  'text.baht': 'THB',
  'text.day': 'day',

  'text.status.done': 'Sucessfully Purchased',
  'text.status.done.userexpired': 'User Expired from',
  'text.status.done.engineexpired': 'Engine Expired from',
  'text.status.done.clientexpired': 'Client Expired from',
  'text.from': 'from',
  'text.to': 'to',

  /*
  'pricing.chart' : 'Live Chart',
  'pricing.chart.desc' : 'Live statistic chart',
  'pricing.notification' : 'Notifycation',
  'pricing.strategy' : 'Strategy Builder',
  'pricing.strategy.desc' : 'Create your stategy up to 20 per user',
  'pricing.selfengine' : 'Use your strategy for automated betting',
  'pricing.selfengine.desc' : 'Use your strategy for automated betting',
  'pricing.followcondition' : 'Follow/Use Hall strategy for automated betting',
  'pricing.followcondition.desc' : 'Follow other user strategy',
  'pricing.systemcondition' : 'Follow/Use system strategy for automated betting',
  'pricing.systemcondition.desc' : 'Use all strategy for automated betting',
  'pricing.guarantee' : 'Profit Guarantee',
  'pricing.guarantee.desc' : 'Guaranteed profit from automated betting up to 20k THB/month',
  'pricing.robot' : 'Robot for automated betting*',
  'pricing.robot.desc' : 'Robot for automated betting*',
  */
  'pricing.helmet':'Pricing',
  'pricing.chart': 'กราฟสถิติฟุตบอลสด',
  'pricing.chart.desc': 'กราฟสถิติฟุตบอลสด',
  'pricing.notification': 'การแจ้งเตือนเงื่อนไข',
  'pricing.notification.desc': 'กราฟสถิติฟุตบอลสด',
  'pricing.strategy': 'สร้างเงื่อนไข',
  'pricing.strategy.desc': 'ตรวจจับเงื่อนไขตามรูปแบบเกมส์ จำกัด 20 เงื่อนไขต่อผู้ใช้งาน',
  'pricing.selfengine': 'ใช้เงื่อนไขส่วนตัวเพื่อลงทุนอัตโนมัติ',
  'pricing.selfengine.desc': 'ใช้เงื่อนไขส่วนตัวเพื่อลงทุนอัตโนมัติ',
  'pricing.followcondition': 'ติดตาม/ใช้เงื่อนไขในทำเนียบเพื่อลงทุนอัตโนมัติ',
  'pricing.followcondition.desc': 'ติดตามเงื่อนไขในทำเนียบ',
  'pricing.systemcondition': 'ติดตาม/ใช้เงื่อนไขระบบเพื่อลงทุนอัตโนมัติ',
  'pricing.systemcondition.desc': 'ติดตามเงื่อนไขระบบเพื่อใช้ลงทุนอัตโนมัติ',
  'pricing.guarantee': 'การันตีกำไร 4 หมื่น/เดือน*',
  'pricing.guarantee.desc': 'หากกำไรไม่ถึง 40,000 บาท ใช้ Full Engine ฟรีในเดือนถัดไป',
  'pricing.robot': 'บอทสำหรับลงทุนอัตโนมัติ*',
  'pricing.robot.desc': 'บอทสำหรับลงทุนอัตโนมัติ*',
  'pricing.robot.explain' : 'เพื่อลดปัญหาที่เคยเกิดขึ้นจากที่ผู้ใช้งานต้องดูแลบอทเอง ทางทีมงานจะนำค่าใช้จ่ายส่วนนี้ ไปทำการเช่าระบบ Cloud Computer เพื่อใช้สำหรับเปิดบอท และคอยตรวจสอบปัญหาต่างๆ ที่เกิดขึ้น',
  'pricing.notincludebot': 'ไม่รวมบอทสำหรับลงทุนอัตโนมัติ',

  'orderstep.order': 'เลือกรูปแบบบริการ',
  'orderstep.payment': 'ชำระเงินตามจำนวน',
  'orderstep.paymentnotify': 'แจ้งชำระเงิน',
  'orderstep.paymentnotify.notfound': 'Payment not found',
  'orderstep.paymentnotify.desc1': 'Please verify your payment date and amount again',
  'orderstep.paymentnotify.desc2': 'Or contact support',
  'orderstep.finished': 'สำเร็จ',
  'orderstep.payment.banktrasfer': 'Bank Transfer',
  'orderstep.payment.banktrasfer.notfify': 'Notify Payment',
  'orderstep.payment.paypal': 'Paypal',
  'orderstep.done.client' : 'Client configuration takes 1-5 minutes, please wait',

  'table.orderplan':'Plan',
  'table.orderquantity':'Quantity',
  'table.orderunit':'Unit Cost',
  'table.ordertotal':'Total',
  'table.strategy': 'Strategy',
  'table.win': 'Win',
  'table.draw': 'Draw',
  'table.lost': 'Lost',
  'table.profit': 'Profit',
  'table.match': 'Match',
  'table.rate': 'Rate',
  'table.lastmonthprofit': 'Last Month Statistic',
  'table.result': 'Result',
  'table.stake': 'Stake',
  'table.return': 'Return',
  'table.time': 'Time',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
