import { all, takeLatest } from 'redux-saga/effects'
import { fetchServerAction, setClientConfigAction, setServerConfigAction } from './actions'
import actionTypes from './actiontypes'

function* watchFetchServerAction() {
  yield takeLatest(actionTypes.GET_SERVER_REQ, fetchServerAction)
}

function* watchSetClientConfigAction() {
  yield takeLatest(actionTypes.SET_SERVER_CLI_CONF_REQ, setClientConfigAction)
}

function* watchSetServerConfigAction() {
  yield takeLatest(actionTypes.SET_SERVER_SV_CONF_REQ, setServerConfigAction)
}

export default function* rootSaga() {
  yield all([watchFetchServerAction(), watchSetClientConfigAction(), watchSetServerConfigAction()])
}
