import actionTypes from './actiontypes'

const initialState = {
  servers: [],
  users: [],
  result: -1,
  isLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
