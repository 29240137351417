import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchClientAction } from './actions'

function* watchGetClientAction() {
  yield takeLatest(actionTypes.GET_CLIENT_REQ, fetchClientAction)
}


export default function* rootSaga() {
  yield all([
    watchGetClientAction(),
  ])
}
