import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import user789 from './user789/reducers'
import banklist from './banklist/reducers'
import invoices from './invoices/reducers'
import client from './client/reducers'
import servers from './servers/reducers'
import failedReport from './failedReport/reducers'
import stakeSummary from './stakeSummary/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    user789,
    banklist,
    invoices,
    client,
    failedReport,
    stakeSummary,
    servers
  })
