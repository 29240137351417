const actiontypes = {

  SET_STATE: 'client/SET_STATE',
  SET_STATE_CLIENT: 'client/SET_STATE_USER',
  SET_LOADING: 'client/SET_LOADING',

  GET_CLIENT_REQ: 'client/GET_CLIENT_REQ',

}

export default actiontypes
