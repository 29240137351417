import apiClient from 'services/axios'

export function postStakeSummaryData(person) {
  console.log('person: ', person)
  return apiClient
    .post(`/stake_summary/stake_summary`, person)
    .then(response => {
      console.log('stakeSummary service data: ', response.data)
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function getUsersData() {
  return apiClient
    .get(`/stake_summary/users`)
    .then(response => {
      console.log('Users stakeSummary service data: ', response.data)
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
